import TicketsRoutes from './Tickets/routes';

export default [
  {
    path: 'support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '@/views/Support/index'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'support_title'
    },
    children: [
      {
        path: 'overview',
        name: 'SupportOverview',
        component: () => import(/* webpackChunkName: "support_overview" */ '@/views/Support/Overview/index.vue'),
        meta: {
          requiresAuth: true,
          agent: true,
          title: 'support_overview_title'
        }
      },
      {
        path: 'departments',
        name: 'Departments',
        component: () => import(/* webpackChunkName: "support_departments" */ '@/views/Support/Departments/index.vue'),
        meta: {
          requiresAuth: true,
          root: true,
          title: 'support_departments_title'
        }
      },
      {
        path: 'help-center',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "support_faq" */ '@/views/Support/Faqs/index.vue'),
        meta: {
          requiresAuth: true,
          agent: true,
          title: 'support_details_title'
        }
      },
      ...TicketsRoutes
    ],
  },
]