export default [
  {
    path: 'widgets',
    name: 'Widgets',
    component: () => import(/* webpackChunkName: "widgets" */ '@/views/Widgets/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'widgets_title'
    }
  }, {
    path: 'widgets/livechat',
    name: 'WidgetsLiveChat',
    component: () => import(/* webpackChunkName: "widgets_chat" */ '@/views/Widgets/LiveChat/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      chat: true,
      title: 'widgets_livechat_title'
    }
  }, {
    path: 'widgets/creditapp',
    name: 'WidgetsCreditApp',
    component: () => import(/* webpackChunkName: "widgets_financing" */ '@/views/Widgets/CreditApplication/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'widgets_credit_app_title'
    }
  }, {
    path: 'widgets/appraisal',
    name: 'WidgetsAppraisal',
    component: () => import(/* webpackChunkName: "widgets_appraisal" */ '@/views/Widgets/Appraisal/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'widgets_appraisal_title'
    }
  }, {
    path: 'widgets/appointment',
    name: 'WidgetsAppointment',
    component: () => import(/* webpackChunkName: "widgets_appointment" */ '@/views/Widgets/Appointment/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'widgets_appointment_title'
    }
  }, {
    path: 'widgets/testdrive',
    name: 'WidgetsTestDrive',
    component: () => import(/* webpackChunkName: "widgets_test_drive" */ '@/views/Widgets/TestDrive/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'widgets_testdrive_title'
    }
  }, {
    path: 'widgets/contact',
    name: 'WidgetsContact',
    component: () => import(/* webpackChunkName: "widgets_contact" */ '@/views/Widgets/Contact/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'widgets_contact_title'
    }
  }, {
    path: 'widgets/ads',
    name: 'WidgetsAds',
    component: () => import(/* webpackChunkName: "widgets_ads" */ '@/views/Widgets/Ads/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'widgets_ads_title'
    }
  }, {
    path: 'widgets/ads/:adId',
    name: 'WidgetsAdsDetails',
    component: () => import(/* webpackChunkName: "widgets_ads_details" */ '@/views/Widgets/Ads/Details/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'widgets_ad_details_title'
    }
  }
]