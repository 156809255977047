export default [
  {
    path: 'settings/trc',
    name: 'TRCIndex',
    component: () => import(/* webpackChunkName: "settings_trc_index" */ '@/views/Settings/Communications/TwilioRegulatoryCompliance/index.vue'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'trc_index_title'
    },
    children: [
      {
        path: 'customer-profiles',
        name: 'TRCCustomerProfiles',
        component: () => import(/* webpackChunkName: "settings_trc_customer_profiles" */ '@/views/Settings/Communications/TwilioRegulatoryCompliance/CustomerProfiles/List/index.vue'),
        meta: {
          requiresAuth: true,
          root: true,
          title: 'trc_customer_profiles_title'
        }
      },
      {
        path: 'trust-products',
        name: 'TRCTrustProducts',
        component: () => import(/* webpackChunkName: "settings_trc_trust_products" */ '@/views/Settings/Communications/TwilioRegulatoryCompliance/TrustProducts/List/index.vue'),
        meta: {
          requiresAuth: true,
          root: true,
          title: 'trc_trust_products_title'
        }
      },
      {
        path: 'brands',
        name: 'TRCBrands',
        component: () => import(/* webpackChunkName: "settings_trc_brands" */ '@/views/Settings/Communications/TwilioRegulatoryCompliance/Brands/List/index.vue'),
        meta: {
          requiresAuth: true,
          root: true,
          title: 'trc_brands_title'
        }
      },
      {
        path: 'messaging-services',
        name: 'TRCMessagingServices',
        component: () => import(/* webpackChunkName: "settings_trc_messaging" */ '@/views/Settings/Communications/TwilioRegulatoryCompliance/MessagingServices/List/index.vue'),
        meta: {
          requiresAuth: true,
          root: true,
          title: 'trc_messaging_services_title'
        }
      },
      {
        path: 'campaigns',
        name: 'TRCCampaigns',
        component: () => import(/* webpackChunkName: "settings_trc_campaigns" */ '@/views/Settings/Communications/TwilioRegulatoryCompliance/Campaigns/List/index.vue'),
        meta: {
          requiresAuth: true,
          root: true,
          title: 'trc_campaigns_title'
        }
      }
    ]
  }
]