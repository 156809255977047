export default [
  {
    path: 'settings/ftp-feed-configurations',
    name: 'FtpFeedConfig',
    component: () => import(/* webpackChunkName: "settings_inventory_feed_config" */ '@/views/Settings/Inventory/FtpFeedConfig/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_ftp_feed_title'
    }
  },
  {
    path: 'settings/ftp-import',
    name: 'FtpFeedConfigImport',
    component: () => import(/* webpackChunkName: "settings_inventory_feed_config_import" */ '@/views/Settings/Inventory/FtpFeedConfig/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_ftp_feed_title_import'
    },
    children: [
      {
        path: '',
        name: 'FtpFeedConfigMatch',
        component: () => import(/* webpackChunkName: "settings_inventory_feed_config_match" */ '@/views/Settings/Inventory/FtpFeedConfig/Match/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_car_inventory_match_title'
        },
      },
      {
        path: 'bodies',
        name: 'FtpFeedConfigBodies',
        component: () => import(/* webpackChunkName: "settings_inventory_feed_config_bodies" */ '@/views/Settings/Inventory/FtpFeedConfig/Bodies/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_car_inventory_match_title'
        },
      },
      {
        path: 'review',
        name: 'FtpFeedConfigReview',
        component: () => import(/* webpackChunkName: "settings_inventory_feed_config_review" */ '@/views/Settings/Inventory/FtpFeedConfig/Review/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_car_inventory_review_title'
        },
      }
    ]
  },
  {
    path: 'settings/car-bodies',
    name: 'CarBodies',
    component: () => import(/* webpackChunkName: "inventory_feed_config_car_bodies" */ '@/views/Settings/Inventory/CarBodies/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      inventory: true,
      title: 'settings_car_bodies'
    }
  },
  {
    path: 'settings/car-images',
    name: 'CarImages',
    component: () => import(/* webpackChunkName: "settings_inventory_feed_config_car_images" */ '@/views/Settings/Inventory/CarImages/index.vue'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'settings_car_images'
    }
  },
]