
export default [
  {
    path: 'finance-applications',
    name: 'FinanceApplicationsList',
    component: () => import(/* webpackChunkName: "finance_applications" */ '@/views/FinanceApplications/List/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'finance_application_list_title'
    },
    props: true,
  },
  {
    path: 'finance-applications/details/:id',
    name: 'FinanceApplicationsDetails',
    component: () => import(/* webpackChunkName: "finance_application_details" */ '@/views/FinanceApplications/Details/index.vue'),
    meta: {
      requiresAuth: true,
      manager: true,
      title: 'finance_application_details_title'
    },
    props: true,
  },
];