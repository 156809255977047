export default [
  {
    path: 'privacy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy_policy" */ '@/views/LegalPolicies/PrivacyPolicy/index.vue'),
    meta: {
      title: 'ezleads_privacy_policy'
    },
  },
  {
    path: 'terms',
    name: 'TermsOfServicePolicy',
    component: () => import(/* webpackChunkName: "term_of_service_policy" */ '@/views/LegalPolicies/TermsOfService/index.vue'),
    meta: {
      title: 'ezleads_terms_of_service'
    },
  },
]