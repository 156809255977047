export default [
  {
    path: 'showroom',
    name: 'ShowroomList',
    component: () => import(/* webpackChunkName: "showroom_list" */ '@/views/Showroom/List/index.vue'),
    meta: {
      requiresAuth: true,
      reception: true,
      title: 'showroom_list_title'
    },
    props: true,
  }, {
    path: 'showroom/create',
    name: 'ShowroomCreate',
    component: () => import(/* webpackChunkName: "showroom_create" */ '@/views/Showroom/Create/index.vue'),
    meta: {
      requiresAuth: true,
      reception: true,
      title: 'showroom_create_title'
    },
    props: true,
  }, {
    path: 'showroom/details/:id',
    name: 'ShowroomDetails',
    component: () => import(/* webpackChunkName: "showroom_details" */ '@/views/Showroom/Details/index.vue'),
    meta: {
      requiresAuth: true,
      reception: true,
      title: 'showroom_details_title'
    },
    props: true,
  },
]