export default [
  {
    path: 'settings/call-center',
    name: 'SettingCallCenterIndex',
    component: () => import(/* webpackChunkName: "settings_call_center" */ '@/views/Settings/Communications/CallCenter/Index/index.vue'),
    meta: {
      requiresAuth: true,
      manager: true,
      title: 'settings_call_center_title'
    },
    props: true,
  }, {
    path: 'settings/call-center/hunt-groups',
    name: 'SettingCallCenterHuntGroups',
    component: () => import(/* webpackChunkName: "settings_call_center_hunt" */ '@/views/Settings/Communications/CallCenter/HuntGroups/index.vue'),
    meta: {
      requiresAuth: true,
      manager: true,
      title: 'hunt-groups_title'
    },
    props: true,
  }, {
    path: 'settings/call-center/queues-groups',
    name: 'SettingCallCenterQueuesGroups',
    component: () => import(/* webpackChunkName: "settings_call_center_queue" */ '@/views/Settings/Communications/CallCenter/Queues/index.vue'),
    meta: {
      requiresAuth: true,
      manager: true,
      title: 'queues-groups_title'
    },
    props: true,
  }, {
    path: 'settings/call-center/greetings',
    name: 'SettingCallCenterGreetings',
    component: () => import(/* webpackChunkName: "settings_call_center_greetings" */ '@/views/Settings/Communications/CallCenter/Greetings/index.vue'),
    meta: {
      requiresAuth: true,
      manager: true,
      title: 'greetings_title'
    },
    props: true,
  }, {
    path: 'settings/call-center/numbers',
    name: 'SettingCallCenterNumbers',
    component: () => import(/* webpackChunkName: "settings_call_center_numbers" */ '@/views/Settings/Communications/CallCenter/Numbers/index.vue'),
    meta: {
      requiresAuth: true,
      manager: true,
      title: 'numbers_title'
    },
    props: true,
  }, {
    path: 'settings/call-center/manage-number/:numberId',
    name: 'SettingCallCenterManageNumber',
    component: () => import(/* webpackChunkName: "settings_call_center_manage_number" */ '@/views/Settings/Communications/CallCenter/ManageNumber/index.vue'),
    meta: {
      requiresAuth: true,
      manager: true,
      title: 'manage_numbers_title'
    },
    props: true,
  },
];