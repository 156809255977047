export default [
  {
    path: 'settings/billing',
    name: 'SettingBillingIndex',
    component: () => import(/* webpackChunkName: "settings_billing" */ '@/views/Settings/Billing/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'billing_title'
    }
  },
  {
    path: 'settings/billing/pay',
    name: 'SettingBillingPayment',
    component: () => import(/* webpackChunkName: "settings_billing_payment" */ '@/views/Settings/Billing/Payment/Payment.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'billing_pay_now_title'
    }
  },
  {
    path: 'settings/billing/invoices',
    name: 'SettingBillingInvoices',
    component: () => import(/* webpackChunkName: "settings_billing_invoices" */ '@/views/Settings/Billing/Invoices'),
    meta: {
      requiresAuth: true,
      root: true,
      support: true,
      title: 'billing_invoices_title'
    }
  },
]