export default [
  {
    path: 'import/leads',
    component: () => import(/* webpackChunkName: "settings_import_leads" */ '@/views/Settings/DataImport/Leads/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_import_leads_title'
    },
    props: true,
    children: [
      {
        path: '',
        name: 'ImportLeadUpload',
        component: () => import(/* webpackChunkName: "settings_import_leads_upload" */ '@/views/Settings/DataImport/Leads/Upload/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_leads_upload_title'
        },
      },
      {
        path: 'match',
        name: 'ImportLeadMatch',
        component: () => import(/* webpackChunkName: "settings_import_leads_match" */ '@/views/Settings/DataImport/Leads/Match/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_leads_match_title'
        },
      },
      {
        path: 'review',
        name: 'ImportLeadReview',
        component: () => import(/* webpackChunkName: "settings_import_leads_review" */ '@/views/Settings/DataImport/Leads/Review/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_leads_review_title'
        },
      },
      {
        path: 'complete',
        name: 'ImportLeadComplete',
        component: () => import(/* webpackChunkName: "settings_import_leads_complete" */ '@/views/Settings/DataImport/Leads/Complete/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_leads_complete_title'
        },
      }
    ]
  },
]