export default [
  {
    path: 'deals',
    name: 'CRMDealList',
    component: () => import(/* webpackChunkName: "crm_deal_list" */ '@/views/CRM/Deals/List/index.vue'),
    meta: {
      requiresAuth: true,
      crm: true,
      agent: true,
      title: 'crm_deal_list_title'
    },
    props: true
  }, {
    path: 'deals/details/:id',
    name: 'CRMDealDetails',
    component: () => import(/* webpackChunkName: "crm_deal_details" */ '@/views/CRM/Deals/Details/index.vue'),
    meta: {
      requiresAuth: true,
      crm: true,
      agent: true,
      title: 'crm_deal_details_title'
    },
    props: true
  },
]