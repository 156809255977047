export default [
  {
    path: 'ftp/ftp-accounts',
    name: 'FtpAccountsList',
    component: () => import(/* ftp_accounts */ '@/views/Ftp/FtpAccounts/index.vue'),
    meta: {
      requiresAuth: true,
      ftp: true,
      agent: true,
      title: 'ftp_accounts_title'
    },
    props: true,
  },
  {
    path: 'ftp/ftp-files',
    name: 'FtpFilesList',
    component: () => import(/* ftp_files */ '@/views/Ftp/FtpFiles/index.vue'),
    meta: {
      requiresAuth: true,
      ftp: true,
      agent: true,
      title: 'ftp_files_title'
    },
    props: true,
  },
]