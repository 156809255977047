export default [
  {
    path: 'settings/import/legal-documents',
    component: () => import(/* webpackChunkName: "settings_import_legal_docs" */ '@/views/Settings/DataImport/LegalDocuments/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_import_legal_document_title'
    },
    props: true,
    children: [
      {
        path: '',
        name: 'ImportLegalDocumentUpload',
        component: () => import(/* webpackChunkName: "settings_import_legal_docs_upload" */ '@/views/Settings/DataImport/LegalDocuments/Upload/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_legal_document_upload_title'
        },
      },
      {
        path: 'match',
        name: 'ImportLegalDocumentMatch',
        component: () => import(/* webpackChunkName: "settings_import_legal_docs_match" */ '@/views/Settings/DataImport/LegalDocuments/Match/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_legal_document_match_title'
        },
      },
      {
        path: 'review',
        name: 'ImportLegalDocumentReview',
        component: () => import(/* webpackChunkName: "settings_import_legal_docs_review" */ '@/views/Settings/DataImport/LegalDocuments/Review/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_legal_document_review_title'
        },
      }
    ]
  },
]