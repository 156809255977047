export default [
  {
    path: 'workplan/agents',
    name: 'AgentsWorkplan',
    component: () => import('@/views/Workplan/Agents/List/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'agents_workplan'
    }
  },
]