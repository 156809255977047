export default [
  {
    path: 'settings/integrations',
    name: 'IntegrationsIndex',
    component: () => import(/* webpackChunkName: "settings_integrations" */ '@/views/Settings/Integrations/Index/index.vue'),
    meta: {
      requiresAuth: true,
      integration: true,
      manager: true,
      title: 'settings_integrations_title'
    },
    props: true,
  },
]