export default [
  {
    path: 'legal-documents',
    name: 'LegalDocumentsList',
    component: () => import(/* legal_documents */ '@/views/LegalDocuments/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_list_legal_document_title'
    },
    props: true,
  },
]