export default [
  {
    path: 'import/deals',
    component: () => import(/* webpackChunkName: "settings_import_deals" */ '@/views/Settings/DataImport/Deals/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_import_deals_title'
    },
    props: true,
    children: [
      {
        path: '',
        name: 'ImportDealUpload',
        component: () => import(/* webpackChunkName: "settings_import_deals_upload" */ '@/views/Settings/DataImport/Deals/Upload/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_deals_upload_title'
        },
      },
      {
        path: 'match',
        name: 'ImportDealMatch',
        component: () => import(/* webpackChunkName: "settings_import_deals_match" */ '@/views/Settings/DataImport/Deals/Match/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_deals_match_title'
        },
      },
      {
        path: 'review',
        name: 'ImportDealReview',
        component: () => import(/* webpackChunkName: "settings_import_deals_review" */ '@/views/Settings/DataImport/Deals/Review/index.vue'),
        meta: {
          requiresAuth: true,
          admin: true,
          manager: true,
          title: 'settings_import_deals_review_title'
        },
      }
    ]
  },
]