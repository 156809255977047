export default [
  {
    path: 'settings/templates',
    name: 'TemplatesIndex',
    component: () => import(/* webpackChunkName: "settings_templates_index" */ '@/views/Settings/Templates/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_template_list_title'
    },
    props: true,
  },
  {
    path: 'settings/templates/mail/categories/:id',
    name: 'MailTemplatesCategory',
    component: () => import(/* webpackChunkName: "settings_templates_category" */ '@/views/Settings/Templates/Email/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'templates_mail_list_title'
    }
  },
  {
    path: 'settings/templates/mail/categories/:categoryId/:templateId',
    name: 'MailTemplatesEditTemplate',
    component: () => import(/* webpackChunkName: "settings_templates_category_details" */ '@/views/Settings/Templates/Email/Edit/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'templates_mail_list_title'
    }
  },
  {
    path: 'settings/templates/list',
    name: 'TemplatesList',
    component: () => import(/* webpackChunkName: "settings_templates_list" */ '@/views/Settings/Templates/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_template_list_title'
    },
    props: true,
  },
  {
    path: 'settings/templates/email',
    name: 'TemplatesEmail',
    component: () => import(/* webpackChunkName: "settings_templates_email" */ '@/views/Settings/Templates/Email/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_template_email_title'
    },
    props: true,
  },
  {
    path: 'settings/templates/sms/categories',
    name: 'SmsTemplatesCategories',
    component: () => import(/* webpackChunkName: "settings_templates_sms" */ '@/views/Settings/Templates/Sms/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_template_sms_title'
    },
    props: true,
  },
  {
    path: 'settings/templates/sms/categories/:id',
    name: 'SmsTemplatesCategory',
    component: () => import(/* webpackChunkName: "settings_templates_sms_category" */ '@/views/Settings/Templates/Sms/TemplateList/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_template_sms_category_title'
    },
    props: true,
  },
  {
    path: 'settings/templates/voicemail',
    name: 'TemplatesVoicemail',
    component: () => import(/* webpackChunkName: "settings_templates_voicemail" */ '@/views/Settings/Templates/Voicemail/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_template_voicemail_title'
    },
    props: true,
  },
  {
    path: 'settings/templates/videos',
    name: 'TemplatesVideos',
    component: () => import(/* webpackChunkName: "settings_templates_videos" */ '@/views/Settings/Templates/Videos/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_template_videos_title'
    },
    props: true,
  },
]