export default [
  {
    path: 'settings/security/firewall',
    name: 'SettingSecurityFirewall',
    component: () => import(/* webpackChunkName: "settings_firewall" */ '@/views/Settings/Security/ACL/index.vue'),
    meta: {
      requiresAuth: true,
      security: true,
      admin: true,
      title: 'settings_security_firewall'
    }
  },
]