const CRM_SETTINGS_PATH = 'settings/crm';

export default [
  {
    path: `${ CRM_SETTINGS_PATH }/lead-rules`,
    name: 'LeadRulesSettings',
    component: () => import(/* webpackChunkName: "settings_lead_rules" */ '@/views/Settings/CRMSettings/LeadRules/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'settings_lead_rules_title'
    }
  }, {
    path: `${ CRM_SETTINGS_PATH }/lead-sources`,
    name: 'LeadSourcesSettings',
    component: () => import(/* webpackChunkName: "settings_lead_sources" */ '@/views/Settings/CRMSettings/LeadSources/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'settings_lead_sources_title'
    }
  }, {
    path: `${ CRM_SETTINGS_PATH }/lead-to-crm`,
    name: 'LeadToCRMSettings',
    component: () => import(/* webpackChunkName: "settings_lead_to_crm" */ '@/views/Settings/CRMSettings/LeadToCRM/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'settings_lead_to_crm_title'
    },
  }, {
    path: `${ CRM_SETTINGS_PATH }/lead-tracking`,
    name: 'LeadTrackingSettings',
    component: () => import(/* webpackChunkName: "settings_lead_tracking" */ '@/views/Settings/CRMSettings/LeadTracking/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      crm: true,
      title: 'settings_lead_tracking_title'
    },
  }
]