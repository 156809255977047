export default [
  {
    path: 'listing/deals',
    name: 'DealListing',
    component: () => import(/* webpackChunkName: "deal_listing" */ '@/views/Listings/DealListings/index.vue'),
    meta: {
      title: 'deal_listings',
      requiresAuth: true,
      agent: true,
    }
  }, {
    path: 'listing/deals/:listingConfigId',
    name: 'DealListingDetails',
    component: () => import(/* webpackChunkName: "deal_listing_details" */ '@/views/Listings/DealListingDetails/index.vue'),
    meta: {
      title: 'deal_listing_details',
      requiresAuth: true,
      agent: true,
    }
  }
]