export default [
  {
    path: 'appraisals',
    name: 'Appraisals',
    component: () => import(/* appraisals */ '@/views/Appraisals/List/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'appraisal_list_title'
    },
    props: true,
  },
]