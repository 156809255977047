export default [
  {
    path: 'cross-sell',
    name: 'CrossSell',
    component: () => import(/* cross_selling */ '@/views/CrossSell/Index/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'cross_sell_title'
    }
  },
  {
    path: 'cross-sell/mapping',
    name: 'CrossSellMapping',
    component: () => import(/* cross_selling_mapping */ '@/views/CrossSell/Mapping/Map/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'cross_sell_mapping'
    }
  },
  {
    path: 'cross-sell/dealers',
    name: 'CrossSellDealers',
    component: () => import(/* cross_selling_dealers */ '@/views/CrossSell/Dealership/Summaries/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'cross_sell_dealers'
    }
  },
  {
    path: 'cross-sell/lienholders',
    name: 'CrossSellLenders',
    component: () => import(/* cross_selling_lenders */ '@/views/CrossSell/Lienholder/Summaries/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'cross_sell_lenders'
    }
  },
]