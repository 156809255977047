export default [
  {
    path: 'chatbot',
    name: 'ChatBot',
    component: () => import(/* webpackChunkName: "chatbot" */ '@/views/ChatBot/Chat/index.vue'),
    meta: {
      title: 'chatbot_title',
      requiresAuth: true,
      agent: true,
    }
  },
  {
    path: 'chatbot/:id',
    name: 'ChatBotDetails',
    component: () => import(/* webpackChunkName: "chatbot" */ '@/views/ChatBot/Chat/index.vue'),
    meta: {
      title: 'chatbot_title',
      requiresAuth: true,
      agent: true,
    }
  },
];