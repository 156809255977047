export default [
  {
    path: 'campaigns',
    name: 'Campaigns',
    component: () => import(/* webpackChunkName: "campaigns" */ '@/views/Campaigns/Dashboard/index.vue'),
    meta: {
      title: 'campaigns_title',
      requiresAuth: true,
      campaigns: true,
      admin: true,
    }
  },
  {
    path: 'campaigns/list',
    name: 'CampaignsList',
    component: () => import(/* webpackChunkName: "campaigns_list" */ '@/views/Campaigns/List/index.vue'),
    meta: {
      title: "campaigns_title",
      requiresAuth: true,
      campaigns: true,
      admin: true,
    }
  },
  {
    path: 'campaigns/details/:id',
    name: 'CampaignDetails',
    component: () => import(/* webpackChunkName: "campaign_details" */ '@/views/Campaigns/Details/index.vue'),
    meta: {
      title: "campaign_details",
      requiresAuth: true,
      campaigns: true,
      admin: true,
    }
  },
  {
    path: 'campaigns/recipients',
    name: 'CampaignsRecipients',
    component: () => import(/* webpackChunkName: "campaigns_recipients" */ '@/views/Campaigns/Recipients/index.vue'),
    meta: {
      title: "recipient_list",
      requiresAuth: true,
      campaigns: true,
      admin: true,
    }
  },
  {
    path: 'campaigns/external',
    name: 'ExternalCampaigns',
    component: () => import(/* webpackChunkName: "external_campaigns" */ '@/views/Campaigns/External/Campaigns/index.vue'),
    meta: {
      title: "external_campaigns_list",
      requiresAuth: true,
      campaigns: true,
      admin: true,
    }
  },
  {
    path: 'campaigns/external/:campaignId/links',
    name: 'ExternalCampaignLinks',
    component: () => import(/* webpackChunkName: "external_campaign_links" */ '@/views/Campaigns/External/CampaignLinks'),
    meta: {
      title: "external_campaigns_link_list",
      requiresAuth: true,
      campaigns: true,
      admin: true,
    }
  },
  {
    path: 'campaigns/external/:campaignId/links/:linkId/insights',
    name: 'ExternalCampaignLinkInsights',
    component: () => import(/* webpackChunkName: "external_campaign_link_insights" */ '@/views/Campaigns/External/CampaignLinkInsights'),
    meta: {
      title: "external_campaigns_link_insight_list",
      requiresAuth: true,
      campaigns: true,
      admin: true,
    }
  }
];