export default [
  {
    path: 'affiliates',
    name: 'AffiliateList',
    component: () => import(/* webpackChunkName: "affiliates" */ '@/views/Affiliates/List/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'affiliates_title'
    },
    props: true,
  },
  {
    path: 'affiliates/details/:id',
    name: 'AffiliateDetails',
    component: () => import(/* webpackChunkName: "affiliate_details" */ '@/views/Affiliates/Details/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'affiliates_details_title'
    },
    props: true
  }
]