export default [
  {
    path: 'chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat/Chat/index.vue'),
    meta: {
      title: 'chat_title',
      requiresAuth: true,
      chat: true,
      agent: true,
    }
  },
  {
    path: 'chat/:id',
    name: 'ChatDetails',
    component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat/Chat/index.vue'),
    meta: {
      title: 'chat_title',
      requiresAuth: true,
      chat: true,
      agent: true,
    }
  },
];