export default [
  {
    path: 'customers',
    name: 'CRMCustomerListIndex',
    component: () => import(/* webpackChunkName: "crm_customer_list_index" */ '@/views/CRM/Customers/Index/index.vue'),
    meta: {
      requiresAuth: true,
      crm: true,
      agent: true,
      title: 'crm_customer_list_title'
    },
    props: true,
    children: [
      {
        path: '',
        name: 'CRMCustomerList',
        component: () => import(/* webpackChunkName: "crm_all_customer_list" */ '@/views/CRM/Customers/AllCustomersList/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_all_customer_list_title'
        },
      },
      {
        path: 'my-customers',
        name: 'CRMCustomerListMy',
        component: () => import(/* webpackChunkName: "crm_my_customers_list" */ '@/views/CRM/Customers/MyCustomersList/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_my_customer_list_title'
        },
      },
      {
        path: 'daily-log',
        name: 'CRMCustomerListDailyLog',
        component: () => import(/* webpackChunkName: "crm_customer_list_daily_log" */ '@/views/CRM/Customers/DailyLog/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_all_customer_daily_list_title'
        },
      },
      {
        path: 'opportunities',
        name: 'CRMCustomerOpportunities',
        component: () => import(/* webpackChunkName: "crm_customer_opportunities" */ '@/views/CRM/Customers/MyOpportunities/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_opportunities_title'
        },
      },
      {
        path: 'task',
        name: 'CRMCustomerTask',
        component: () => import(/* webpackChunkName: "crm_customer_task" */ '@/views/CRM/Customers/Task/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_task_title'
        },
      },
      {
        path: 'appointments',
        name: 'CRMCustomerAppointments',
        component: () => import(/* webpackChunkName: "crm_customer_appointments" */ '@/views/CRM/Customers/AppointmentList/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_appointments_title'
        },
      },
      {
        path: 'closed',
        name: 'CRMCustomerClosed',
        component: () => import(/* webpackChunkName: "crm_customer_closed" */ '@/views/CRM/Customers/ClosedList/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_closed_title'
        },
      },
      {
        path: 'inactive',
        name: 'CRMCustomerInactive',
        component: () => import(/* webpackChunkName: "crm_customer_inactive" */ '@/views/CRM/Customers/InactiveList/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_inactive_list_title'
        },
      },
      {
        path: 'mediators',
        name: 'CRMCustomerMediators',
        component: () => import(/* webpackChunkName: "crm_customer_mediators" */ '@/views/CRM/Customers/Mediators/index.vue'),
        meta: {
          requiresAuth: true,
          crm: true,
          agent: true,
          title: 'crm_customer_mediators'
        },
      }
    ]
  }, {
    path: 'customers/create',
    name: 'CRMCustomerCreate',
    component: () => import(/* webpackChunkName: "crm_customer_create" */ '@/views/CRM/Customers/Create/index.vue'),
    meta: {
      requiresAuth: true,
      crm: true,
      manager: true,
      title: 'crm_customer_create_title'
    },
    props: true,
  }, {
    path: 'customers/details/:id',
    name: 'CRMCustomerDetails',
    component: () => import(/* webpackChunkName: "crm_customer_details" */ '@/views/CRM/Customers/Details/index.vue'),
    meta: {
      requiresAuth: true,
      crm: true,
      agent: true,
      title: 'crm_customer_details_title'
    },
    props: true,
  }
]