export default [
  {
    path: 'settings/twilio-content-templates',
    name: 'TwilioContentTemplatesList',
    component: () => import(/* webpackChunkName: "settings_twlo_templates" */ '@/views/Settings/Communications/TwilioContentTemplates/List/index.vue'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'twlo_content_templates'
    },
  },
  {
    path: 'settings/twilio-content-templates/details/:sid',
    name: 'TwilioContentTemplatesEdit',
    component: () => import(/* webpackChunkName: "settings_twlo_templates_details" */ '@/views/Settings/Communications/TwilioContentTemplates/Details/index.vue'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'twlo_content_templates_edit'
    },
  }
]