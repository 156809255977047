export default [
  {
    path: 'import/car_inventory',
    component: () => import(/* webpackChunkName: "settings_import_inventory" */ '@/views/Settings/DataImport/CarInventory/Index/index.vue'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'settings_import_car_inventory_title'
    },
    props: true,
    children: [
      {
        path: '',
        name: 'ImportCarInventoryUpload',
        component: () => import(/* webpackChunkName: "settings_import_inventory_upload" */ '@/views/Settings/DataImport/CarInventory/Upload/index.vue'),
        meta: {
          requiresAuth: true,
          inventory: true,
          title: 'settings_import_car_inventory_upload_title'
        },
      },
      {
        path: 'match',
        name: 'ImportCarInventoryMatch',
        component: () => import(/* webpackChunkName: "settings_import_inventory_match" */ '@/views/Settings/DataImport/CarInventory/Match/index.vue'),
        meta: {
          requiresAuth: true,
          inventory: true,
          title: 'settings_import_car_inventory_match_title'
        },
      },
      {
        path: 'bodies',
        name: 'ImportCarInventoryBodies',
        component: () => import(/* webpackChunkName: "settings_import_inventory_bodies" */ '@/views/Settings/DataImport/CarInventory/Bodies/index.vue'),
        meta: {
          requiresAuth: true,
          inventory: true,
          title: 'settings_import_car_inventory_match_title'
        },
      },
      {
        path: 'review',
        name: 'ImportCarInventoryReview',
        component: () => import(/* webpackChunkName: "settings_import_inventory_review" */ '@/views/Settings/DataImport/CarInventory/Review/index.vue'),
        meta: {
          requiresAuth: true,
          inventory: true,
          title: 'settings_import_car_inventory_review_title'
        },
      }
    ]
  },
]