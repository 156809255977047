export default [
  {
    path: 'promotions',
    name: 'Promotions',
    component: () => import(/* webpackChunkName: "promotions" */ '@/views/Promotions/Promotions/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'promotions_title'
    },
    props: true,
  }, {
    path: 'promotions/type-selector',
    name: 'PromotionTypeSelector',
    component: () => import(/* webpackChunkName: "promotion_type_selector" */ '@/views/Promotions/TypeSelector/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'promotions_title'
    },
    props: true,
  }, {
    path: 'promotions/create',
    name: 'PromotionCreateFlow',
    component: () => import(/* webpackChunkName: "promotion_create_flow" */ '@/views/Promotions/CreateFlow/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'promotions_title'
    },
    props: true,
  },
]