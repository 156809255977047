export default [
  {
    path: 'settings/automations',
    name: 'AutomationsList',
    component: () => import(/* webpackChunkName: "settings_automation" */ '@/views/Settings/Communications/Automations/List/index.vue'),
    meta: {
      requiresAuth: true,
      communication: true,
      admin: true,
      title: 'automations_title'
    }
  },
  {
    path: 'settings/automations/create',
    name: 'AutomationsCreate',
    component: () => import(/* webpackChunkName: "settings_automation_create" */ '@/views/Settings/Communications/Automations/Details/index.vue'),
    meta: {
      requiresAuth: true,
      communication: true,
      admin: true,
      title: 'automations_create_title'
    }
  },
  {
    path: 'settings/automations/details/:id',
    name: 'AutomationsDetails',
    component: () => import(/* webpackChunkName: "settings_automation_details" */ '@/views/Settings/Communications/Automations/Details/index.vue'),
    meta: {
      requiresAuth: true,
      communication: true,
      admin: true,
      title: 'automations_edit_title'
    }
  },
]