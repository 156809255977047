import Cars from "./Cars/routes";

export default [
  ...Cars,
  {
    path: 'inventory',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '@/views/Inventory/Dashboard/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'inventory_title'
    }
  },
  {
    path: 'catalog',
    name: 'Catalog',
    component: () => import(/* webpackChunkName: "inventory_catalog" */ '@/views/Inventory/Catalog/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'catalog_title'
    }
  },
  {
    path: 'car-tracker',
    name: 'CarTracker',
    component: () => import(/* webpackChunkName: "inventory_tracker" */ '@/views/Inventory/CarTracker/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'car_tracker_title'
    }
  },
  {
    path: 'inventory/prices',
    name: 'InventoryPrices',
    component: () => import(/* webpackChunkName: "inventory_prices" */ '@/views/Inventory/Prices/index.vue'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'inventory_prices_title'
    },
    props: true,
  }
]

