export default [
  {
    path: 'link-tree',
    name: 'LinkTreeIndex',
    component: () => import(/* link_tree */ '@/views/LinkTree/Index/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'link_tree_title'
    },
    children: [
      {
        path: 'dashboard',
        name: 'LinkTreeDashboard',
        component: () => import(/* link_tree_dashboard */ '@/views/LinkTree/Dashboard/index.vue'),
        meta: {
          requiresAuth: true,
          agent: true,
          title: 'link_tree_dashboard_title'
        }
      },
      {
        path: 'links',
        name: 'LinkTreeLinks',
        component: () => import(/* link_tree_links */ '@/views/LinkTree/Links/index.vue'),
        meta: {
          requiresAuth: true,
          agent: true,
          title: 'link_tree_links_title'
        }
      },
      {
        path: 'appearance',
        name: 'LinkTreeAppearance',
        component: () => import(/* link_tree_appearance */ '@/views/LinkTree/Appearance/index.vue'),
        meta: {
          requiresAuth: true,
          agent: true,
          title: 'link_tree_appearance_title'
        }
      },
      {
        path: 'settings',
        name: 'LinkTreeSettings',
        component: () => import(/* link_tree_settings */ '@/views/LinkTree/Settings/index.vue'),
        meta: {
          requiresAuth: true,
          agent: true,
          title: 'link_tree_settings_title'
        }
      }
    ]
  }
]