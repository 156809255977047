export default [
  {
    path: 'callcenter',
    name: 'CallCenter',
    component: () => import(/* webpackChunkName: "call_center" */ '@/views/CallCenter/Dashboard/index.vue'),
    meta: {
      requiresAuth: true,
      callCenter: true,
      agent: true,
      title: 'call_center_title'
    }
  }, {
    path: 'callcenter/calls',
    name: 'CallCenterCalls',
    component: () => import(/* webpackChunkName: "call_center_calls" */ '@/views/CallCenter/Calls/index.vue'),
    meta: {
      requiresAuth: true,
      callCenter: true,
      agent: true,
      title: 'call_center_calls_title'
    }
  }, {
    path: 'callcenter/live',
    name: 'CallCenterLive',
    component: () => import(/* webpackChunkName: "call_center_live" */ '@/views/CallCenter/Live/index.vue'),
    meta: {
      requiresAuth: true,
      callCenter: true,
      agent: true,
      title: 'call_center_live_title'
    }
  }
];