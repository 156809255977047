import LeadsRoutes from './Leads/routes';
import DealsRoutes from './Deals/routes';
import CarInventory from './CarInventory/routes';
import LegalDocuments from './LegalDocuments/routes';

export default [
  {
    path: 'import',
    name: 'ImportIndex',
    component: () => import(/* webpackChunkName: "settings_import_index" */ '@/views/Settings/DataImport/Index/index.vue'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'settings_import_title'
    },
    props: true,
  }, {
    path: 'settings/import/car_raw',
    name: 'ImportCarRaw',
    component: () => import(/* webpackChunkName: "settings_import_car_raw" */ '@/views/Settings/DataImport/CarRaw/index.vue'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'settings_import_raw_title'
    },
    props: true,
  }, {
    path: 'settings/import/car',
    name: 'ImportCar',
    component: () => import(/* webpackChunkName: "settings_import_car" */ '@/views/Settings/DataImport/index'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'inventory_import'
    },
    props: true,
  }, {
    path: 'settings/import/car_inventory',
    name: 'ImportCarInventory',
    component: () => import(/* webpackChunkName: "settings_import_car_inventory" */ '@/views/Settings/DataImport/CarInventory/index.vue'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'settings_import_inventory_title'
    },
    props: true,
  }, {
    path: 'settings/import/sales_stats',
    name: 'ImportSalesStats',
    component: () => import(/* webpackChunkName: "settings_import_sales_stats" */ '@/views/Settings/DataImport/SalesStats/index.vue'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'settings_import_sales_title'
    },
    props: true,
  }, {
    path: 'settings/import/postal_codes',
    name: 'ImportZipCodes',
    component: () => import(/* webpackChunkName: "settings_import_zip_codes" */ '@/views/Settings/DataImport/PostalCodes/index'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'settings_import_zipcodes_title'
    },
    props: true,
  }, {
    path: 'settings/import/list/:name',
    name: 'DataImportList',
    component: () => import(/* webpackChunkName: "settings_import_list" */ '@/views/Settings/DataImport/List/index'),
    meta: {
      requiresAuth: true,
      inventory: true,
      title: 'settings_import_list_title'
    },
    props: true,
  },
  ...LeadsRoutes,
  ...DealsRoutes,
  ...CarInventory,
  ...LegalDocuments
]