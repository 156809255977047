export default [
  {
    path: 'settings/locations',
    name: 'LocationList',
    component: () => import(/* webpackChunkName: "settings_locations" */ '@/views/Settings/Locations/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'location_list_title'
    },
    props: true
  },
]