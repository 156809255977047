import CompanyNavRoutes from './Nav/routes';

export default [
  {
    path: 'companies',
    name: 'CompanyList',
    component: () => import(/* webpackChunkName: "companies" */ '@/views/Companies/List/index.vue'),
    meta: {
      requiresAuth: true,
      reseller: true,
      title: 'company_list_title'
    }
  }, {
    path: 'companies/create',
    name: 'CompanyCreate',
    component: () => import(/* webpackChunkName: "companies_create" */ '@/views/Companies/Create/index.vue'),
    meta: {
      requiresAuth: true,
      reseller: true,
      title: 'company_create_title'
    }
  }, {
    path: 'companies/export/:id',
    name: 'CompanyExport',
    component: () => import(/* webpackChunkName: "companies_export" */ '@/views/Companies/Export/index.vue'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'company_export_title'
    }
  },
  ...CompanyNavRoutes
];