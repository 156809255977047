export default [
  {
    path: 'settings/action-plans',
    name: 'ActionPlansList',
    component: () => import(/* webpackChunkName: "settings_action_plan" */ '@/views/Settings/Communications/ActionPlans/List/index.vue'),
    meta: {
      requiresAuth: true,
      communication: true,
      admin: true,
      title: 'action_plans_title'
    }
  }, {
    path: 'settings/action-plans/create',
    name: 'ActionPlansCreate',
    component: () => import(/* webpackChunkName: "settings_action_plan_create" */ '@/views/Settings/Communications/ActionPlans/Details/index.vue'),
    meta: {
      requiresAuth: true,
      communication: true,
      admin: true,
      title: 'action_plans_create_title'
    }
  }, {
    path: 'settings/action-plans/details/:id',
    name: 'ActionPlansDetails',
    component: () => import(/* webpackChunkName: "settings_action_plan_details" */ '@/views/Settings/Communications/ActionPlans/Details/index.vue'),
    meta: {
      requiresAuth: true,
      communication: true,
      admin: true,
      title: 'action_plans_edit_title'
    }
  }
]