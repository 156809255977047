import CRMSettingsRoutes from './CRMSettings/routes';
import AgentsRoutes from './Agents/routes';
import DataExportRoutes from "./DataExport/routes";
import ImportRoutes from "./DataImport/routes";
import EmailAccountsRoutes from "./EmailAccounts/routes";
import GroupsRoutes from './SalesGroup/routes';
import LocationsRoutes from './Locations/routes';
import TemplateRoutes from './Templates/routes';
import IntegrationsRoutes from './Integrations/routes';
import BillingRoutes from './Billing/routes';
import SecurityRoutes from './Security/routes';
import InventoryRoutes from './Inventory/routes';
import CallCenterRoutes from './Communications/CallCenter/routes';
import AutomationsRoutes from './Communications/Automations/routes';
import ActionPlansRoutes from './Communications/ActionPlans/routes';
import TwilioRegulatoryComplianceRoutes from './Communications/TwilioRegulatoryCompliance/routes';
import TwilioContentTemplatesRoutes from './Communications/TwilioContentTemplates/routes';

export default [
  {
    path: 'settings',
    name: 'SettingsIndex',
    component: () => import(/* webpackChunkName: "settings_index" */ '@/views/Settings/Index/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'settings_title'
    }
  }, {
    path: 'settings/overview',
    name: 'SettingOverview',
    component: () => import(/* webpackChunkName: "settings_overview" */ '@/views/Settings/Dashboard/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_title'
    }
  }, {
    path: 'settings/avatars',
    name: 'Avatars',
    component: () => import(/* webpackChunkName: "settings_avatars" */ '@/views/Settings/Avatars/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_title'
    }
  }, {
    path: 'settings/frames',
    name: 'Frames',
    component: () => import(/* webpackChunkName: "settings_frames" */ '@/views/Settings/Frames/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_frames_title'
    }
  }, {
    path: 'settings/import',
    name: 'DataImport',
    component: () => import(/* webpackChunkName: "settings_data_import" */ '@/views/Settings/DataImport/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_import_title'
    }
  }, {
    path: 'settings/maintenance',
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "settings_maintenance" */ '@/views/Settings/Maintenance/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_maintenance_title'
    }
  }, {
    path: 'settings/mycompany',
    name: 'MyCompany',
    component: () => import(/* webpackChunkName: "settings_company" */ '@/views/Settings/MyCompany/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_my_company_title'
    }
  }, {
    path: 'settings/score',
    name: 'SettingScoreLevels',
    component: () => import(/* webpackChunkName: "settings_score" */ '@/views/Settings/ScoreLevels/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_score_title'
    }
  }, {
    path: 'settings/labels',
    name: 'ConversationLabels',
    component: () => import(/* webpackChunkName: "settings_conversation_label" */ '@/views/Settings/ConversationLabels/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'conversation_labels_title'
    }
  }, {
    path: 'settings/tracker',
    name: 'TrackerUsers',
    component: () => import(/* webpackChunkName: "settings_user_trackers" */ '@/views/Settings/Tracker/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'tracker_setup_title'
    }
  }, {
    path: 'settings/business-card',
    name: 'BusinessCard',
    component: () => import(/* webpackChunkName: "settings_business_card" */ '@/views/Settings/BusinessCard/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'business_card_title'
    }
  }, {
    path: 'settings/lien-holder',
    name: 'LienHolder',
    component: () => import(/* webpackChunkName: "settings_lien_holders" */ '@/views/Settings/LienHolders/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'lien_holder_title'
    }
  }, {
    path: 'settings/icon-gallery',
    name: 'IconGallery',
    component: () => import(/* webpackChunkName: "settings_icons" */ '@/views/Settings/IconGallery/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'icon_gallery_title'
    },
  }, {
    path: 'settings/split-leads',
    name: 'SplitLeads',
    component: () => import(/* webpackChunkName: "settings_split_leads" */ '@/views/Settings/SplitLeads/index.vue'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'split_leads_title'
    },
  },

  ...CRMSettingsRoutes,
  ...AgentsRoutes,
  ...DataExportRoutes,
  ...ImportRoutes,
  ...EmailAccountsRoutes,
  ...GroupsRoutes,
  ...LocationsRoutes,
  ...TemplateRoutes,
  ...IntegrationsRoutes,
  ...BillingRoutes,
  ...SecurityRoutes,
  ...InventoryRoutes,
  ...CallCenterRoutes,
  ...AutomationsRoutes,
  ...ActionPlansRoutes,
  ...TwilioRegulatoryComplianceRoutes,
  ...TwilioContentTemplatesRoutes
]