export default [
  {
    path: '/dashboard/facebook-posts',
    name: 'CRMFacebookListPosts',
    component: () => import(/* webpackChunkName: "crm_facebook_posts" */ '@/views/CRM/FacebookPosts/List/index'),
    meta: {
      requiresAuth: true,
      bdc: true,
      title: 'crm_facebook_post_list_title'
    },
    props: true
  },
  {
    path: 'facebook-posts/:id',
    name: 'CRMFacebookPostDetails',
    component: () => import(/* webpackChunkName: "crm_facebook_post_details" */ '@/views/CRM/FacebookPosts/Details/index'),
    meta: {
      requiresAuth: true,
      bdc: true,
      title: 'crm_facebook_post_details_title'
    },
    props: true
  },
]