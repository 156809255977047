export default [
  {
    path: 'companies/nav/:id',
    name: 'CompanyNav',
    component: () => import(/* webpackChunkName: "company_nav" */ '@/views/Companies/Nav/Index/index.vue'),
    meta: {
      requiresAuth: true,
      reseller: true,
      title: 'company_nav_title'
    }
  },
  {
    path: 'companies/nav/:id/billing-packages',
    name: 'CompanyBillingPackages',
    component: () => import(/* webpackChunkName: "company_nav_billing" */ '@/views/Companies/Nav/BillingPackages/index.vue'),
    meta: {
      requiresAuth: true,
      reseller: true,
      title: 'billing_packages_nav'
    }
  },
  {
    path: 'companies/nav/:id/details',
    name: 'CompanyDetails',
    component: () => import(/* webpackChunkName: "company_nav_details" */ '@/views/Companies/Nav/CompanyDetails/index.vue'),
    meta: {
      requiresAuth: true,
      reseller: true,
      title: 'company_details_nav'
    }
  }
];