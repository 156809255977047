export default [
  {
    path: 'tickets',
    name: 'Tickets',
    component: () => import(/* webpackChunkName: "support_tickets"  */ '@/views/Support/Tickets/index'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'support_tickets_title'
    }
  },
  {
    path: 'tickets-create',
    name: 'CreateTicket',
    component: () => import(/* webpackChunkName: "support_tickets_create"  */ '@/views/Support/Tickets/Create/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'support_tickets_create_title'
    }
  }, {
    path: 'tickets/view/:id',
    name: 'ViewTicket',
    component: () => import(/* webpackChunkName: "support_tickets_view"  */ '@/views/Support/Tickets/View/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'support_tickets_details_title'
    }
  },
]