import Customers from './Customers/routes';
import Deals from './Deals/routes';
import FacebookPosts from './FacebookPosts/routes';

export default [
  {
    path: 'crm',
    component: () => import(/* webpackChunkName: "crm_index" */ '@/views/CRM/CRMIndex.vue'),
    meta: {
      requiresAuth: true,
      crm: true,
      agent: true,
      title: 'crm_dashboard_title'
    },
    children: [{
      path: '',
      name: 'CRMDashboard',
      component: () => import(/* webpackChunkName: "crm_dashboard" */ '@/views/CRM/Dashboard/index.vue'),
      meta: {
        requiresAuth: true,
        crm: true,
        agent: true,
        title: 'crm_dashboard_title'
      }
    }, {
      path: 'leads',
      name: 'CRMLeads',
      component: () => import(/* webpackChunkName: "crm_leads" */ '@/views/CRM/Leads/index.vue'),
      meta: {
        requiresAuth: true,
        crm: true,
        agent: true,
        title: 'crm_leads_title'
      }
    }, {
      path: 'customer-assignment',
      name: 'CRMCustomerAssignment',
      component: () => import(/* webpackChunkName: "crm_customer_assignment" */ '@/views/CRM/CustomerAssignment/index.vue'),
      meta: {
        requiresAuth: true,
        crm: true,
        agent: true,
        title: 'crm_customer_assignment_title'
      }
    }, {
      path: 'appts',
      name: 'CRMAppts',
      component: () => import(/* webpackChunkName: "crm_appts" */ '@/views/CRM/Appts/index.vue'),
      meta: {
        requiresAuth: true,
        crm: true,
        agent: true,
        title: 'crm_appointments_title'
      }
    }, {
      path: 'import/:type',
      name: 'LeadImport',
      component: () => import(/* webpackChunkName: "settings_lead_import" */ '@/views/Settings/DataImport/index.vue'),
      meta: {
        requiresAuth: true,
        crm: true,
        admin: true,
        title: 'crm_lead_import'
      }
    }, {
      path: 'invitations',
      name: 'FeedbackInvitation',
      component: () => import(/* webpackChunkName: "feedback_invitation" */ '@/views/CRM/FeedbackInvitations/index.vue'),
      meta: {
        requiresAuth: true,
        crm: true,
        agent: true,
        title: 'crm_feedback_invitation'
      }
    },
      ...Deals,
      ...Customers,
      ...FacebookPosts,
    ]
  },
]