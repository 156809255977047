export default [
  {
    path: 'settings/email-accounts',
    name: 'EmailList',
    component: () => import(/* webpackChunkName: "settings_emails" */ '@/views/Settings/EmailAccounts/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_email_accounts_title'
    },
    props: true,
  },
  {
    path: 'settings/email-accounts/details/:id',
    name: 'EmailDetails',
    component: () => import(/* webpackChunkName: "settings_email_details" */ '@/views/Settings/EmailAccounts/Details/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_email_accounts_details_title'
    },
    props: true
  },
]