export default [
  {
    path: 'accounting/taxes',
    name: 'TaxesReport',
    component: () => import(/* webpackChunkName: "tax_report" */ '@/views/Accounting/TaxesReport/index.vue'),
    meta: {
      requiresAuth: true,
      accountant: true,
      title: 'accounting_taxes_title'
    }
  },
  {
    path: 'accounting/taxes/printable',
    name: 'FilteredReport',
    component: () => import(/* webpackChunkName: "tax_printable_report" */ '@/views/Accounting/FilteredReport/index.vue'),
    meta: {
      requiresAuth: true,
      accountant: true,
      title: 'accounting_taxes_printable_title'
    }
  }
]

