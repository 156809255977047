export default [
  {
    path: 'reporting',
    name: 'ReportingIndex',
    component: () => import(/* webpackChunkName: "reporting_index" */ '@/views/Reporting/Index/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'reporting_title'
    }
  },
  {
    path: 'reporting/agent-activity',
    name: 'AgentActivityReport',
    component: () => import(/* webpackChunkName: "agent_activity_report" */ '@/views/Reporting/AgentActivity/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'agent_activity_report'
    },
  },
  {
    path: 'reporting/calls',
    name: 'CallsReport',
    component: () => import(/* webpackChunkName: "calls_report" */ '@/views/Reporting/AgentActivity/Calls/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'call_report'
    },
  },
  {
    path: 'reporting/calls/logs',
    name: 'CallLogsReport',
    component: () => import(/* webpackChunkName: "calls_report" */ '@/views/Reporting/AgentActivity/Calls/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'call_logs_report'
    },
  },
  {
    path: 'reporting/appointments',
    name: 'AppointmentsReport',
    component: () => import(/* webpackChunkName: "appointments_report" */ '@/views/Reporting/AgentActivity/Appointments/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'appointment_report'
    },
  },
  {
    path: 'reporting/leads',
    name: 'LeadsReport',
    component: () => import(/* webpackChunkName: "leads_report" */ '@/views/Reporting/AgentActivity/Leads/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'lead_report'
    },
  },
  {
    path: 'reporting/lead-sources',
    name: 'LeadSources',
    component: () => import(/* webpackChunkName: "lead_sources" */ '@/views/Reporting/LeadSources/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'lead_sources'
    },
  },
  {
    path: 'reporting/lead-sources/:report',
    name: 'LeadSourcesDetails',
    component: () => import(/* webpackChunkName: "lead_sources_details" */ '@/views/Reporting/LeadSources/Details/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'reporting_details_title'
    }
  },
  {
    path: 'reporting/car-sources',
    name: 'CarSources',
    component: () => import(/* webpackChunkName: "car_sources" */ '@/views/Reporting/CarSources/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'car_sources'
    },
  },
  {
    path: 'reporting/car-sources/:report',
    name: 'CarSourcesDetails',
    component: () => import(/* webpackChunkName: "car_sources_details" */ '@/views/Reporting/CarSources/Details/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'car_source_details_title'
    }
  },
]
