export default [
  {
    path: 'settings/export',
    name: 'DataExport',
    component: () => import(/* webpackChunkName: "settings_export_index" */ '@/views/Settings/DataExport/Index/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_data-export-index'
    }
  },
  {
    path: 'settings/export/list/:name',
    name: 'DataExportList',
    component: () => import(/* webpackChunkName: "settings_export_list" */ '@/views/Settings/DataExport/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_data-export-list_title'
    }
  },
  {
    path: 'settings/export/customers',
    name: 'ExportCustomers',
    component: () => import(/* webpackChunkName: "settings_export_customers" */ '@/views/Settings/DataExport/ExportCustomers/List/index.vue'),
    meta: {
      requiresAuth: true,
      root: true,
      title: 'settings_data_export_customers_title'
    }
  },
  {
    path: 'settings/export/inventory',
    name: 'ExportInventory',
    component: () => import(/* webpackChunkName: "settings_export_inventory" */ '@/views/Settings/DataExport/ExportInventory/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_data-export-inventory_title'
    }
  },
  {
    path: 'settings/export/ftp-feed',
    name: 'FtpFeedJobs',
    component: () => import(/* webpackChunkName: "settings_export_feed" */ '@/views/Settings/DataExport/FtpFeedJobs/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      title: 'settings_data-export-ftp_title'
    }
  },
]