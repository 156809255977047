export default [
  {
    path: 'settings/agents',
    name: 'AgentList',
    component: () => import(/* webpackChunkName: "settings_agents" */ '@/views/Settings/Agents/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_agents_title'
    },
    props: true,
  },
  {
    path: 'settings/agents/details/:id',
    name: 'AgentDetails',
    component: () => import(/* webpackChunkName: "settings_agent_details" */ '@/views/Settings/Agents/Details/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_agents_details_title'
    },
    props: true
  },
  {
    path: 'settings/agents/create',
    name: 'AgentCreate',
    component: () => import(/* webpackChunkName: "settings_agent_create" */ '@/views/Settings/Agents/Create/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_agents_create_title'
    },
    props: true,
  },
]

