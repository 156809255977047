export default [
  {
    path: 'settings/groups',
    name: 'GroupList',
    component: () => import(/* webpackChunkName: "settings_groups" */ '@/views/Settings/SalesGroup/List/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_groups_title'
    },
    props: true,
  },
  {
    path: 'settings/groups/details/:id',
    name: 'GroupDetails',
    component: () => import(/* webpackChunkName: "settings_group_details" */ '@/views/Settings/SalesGroup/Details/index.vue'),
    meta: {
      requiresAuth: true,
      admin: true,
      manager: true,
      title: 'settings_groups_details_title'
    },
    props: true
  },
]