export default [
  {
    path: 'no-access',
    name: 'NoAccess',
    component: () => import(/* webpackChunkName: "no_access" */ '@/views/NoAccess/index.vue'),
    meta: {
      requiresAuth: true,
      agent: true,
      title: 'home_title'
    }
  },
]